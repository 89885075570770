<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveForm)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.products') }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>

                <InputWithValidation class="mb-5" rules="required|min:2" :label="$tc('fields.name')" v-model="form.name" />
                
                <div class="columns is-multiline is-mobile">
                    <div v-if="types.length > 0" class="column is-12-mobile is-6-tablet">
                        <SelectMultiple :input_label="$tc('menu.types', 2)" v-model="form.types" :options="types" track-by="name" label="name" />
                    </div>

                    <div v-if="categories.length > 0" class="column is-12-mobile is-6-tablet">
                        <SelectMultiple :input_label="$tc('menu.categories', 2)" v-model="form.categories" :options="categories" track-by="name" label="name" />
                    </div>
                </div>
                
                <b-field :label="$t('fields.description')" >
                    <vue-editor v-model="form.description" :editor-toolbar="toolbar" /> 
                </b-field>                

                <div v-if="variations.length > 0">
                    <h2>Variações</h2>
                    <div v-for="variation in variations" :key="variation.id" class="column is-12-mobile is-6-tablet">
                        <InputWithValidation v-if="variation.type == 'text'" :label="variation.name" v-model="variation.value" disabled />

                        <SelectMultiple v-if="variation.type == 'select'" :input_label="variation.name" v-model="variation.value" :options="variation.variations" track-by="name" label="name" />

                        <button @click="deleteVariation(variation)">Excluir</button>
                    </div>
                </div>

                <div v-if="attributes.length > 0">
                    <h2>Atributos</h2>
                    <div v-for="attribute in attributes" :key="attribute.id" class="column is-12-mobile is-6-tablet">
                        <InputWithValidation :label="attribute.name" v-model="attribute.value" />

                        <button @click="deleteAttribute(attribute)">Excluir</button>
                    </div>
                </div>

                <div class="columns is-multiline is-mobile">
                    <div class="column is-12-mobile is-6-tablet">
                        <b-field :label="$t('fields.price')">
                            <input class="input" type="text" v-model.lazy="form.price" v-money="money">
                        </b-field>
                    </div>

                    <div class="column is-12-mobile is-6-tablet">
                        <b-field :label="$t('fields.discount_price')">
                            <input class="input" type="text" v-model.lazy="form.discount_price" v-money="money">
                        </b-field>
                    </div>
                </div>

                <div class="columns is-multiline is-mobile">
                    <div class="column is-12-mobile is-6-tablet">
                        <h3 class="is-semibold has-text-dark">{{ $tc('fields.image') }}</h3>

                        <b-button class="is-outlined is-dark mt-4">
                            <b-upload v-model="form.image" accept="image/png, image/jpeg, image/gif, image/svg+xml" drag-drop>
                                {{ form.image ? form.image.name : $tc('fields.attach') }}
                            </b-upload>
                        </b-button>
                    </div>

                    <div class="column is-12-mobile is-6-tablet">
                        <h3 class="is-semibold has-text-dark">{{ $tc('fields.gallery') }}</h3>

                        <b-button class="is-outlined is-dark mt-4">
                            <b-upload v-model="form.images" accept="image/png, image/jpeg, image/gif, image/svg+xml" multiple drag-drop>
                                {{ form.images.length > 0 ? `${form.images.length} ${$t('selected')}` : $t('fields.attach') }}
                            </b-upload>
                        </b-button>
                    </div>
                </div>

                <small class="modal-updated" v-if="form.updated_at">{{ $t('last_change') }} {{ format(form.updated_at) }}</small>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('buttons.save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectMultiple from '@/components/inputs/SelectMultiple'
import { VueEditor } from 'vue2-editor'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import { VMoney } from 'v-money'
import '@/mixins/generic'

export default {
    name: 'ModalProduct',
	components: {
		InputWithValidation,
        SelectMultiple,
        VueEditor,
		ValidationObserver
	},
    directives: {
		money: VMoney
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			visible: false,
            toolbar: this.defaultToolbar(),
			form: {
				name: '',
				description: '',
                price: '0',
                discount_price: '0',
				types: [],
                categories: [],
                image: null,
                images: [],
                attributes: []
			},
            imageProduct: null,
            imagesProduct: [],
            money: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				precision: 0
			},
			types: [],
            categories: [],
            attributes: [],
            variations: [],
            originalProduct: null,
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		}
	},
	methods: {
		async findAllTypes() {
            this.isOpening = true
            try {
                const response = await Api.get('types/findAll')
                if (response.status === 200) {
                    this.types = response.data
                    this.isOpening = false
                }
            } catch (e) {
                console.log(e)
            }
        },
        async findAllCategories() {
            this.isOpening = true
            try {
                const response = await Api.get('categories/findAll')
                if (response.status === 200) {
                    this.categories = response.data
                    this.isOpening = false 
                }
            } catch (e) {
                console.log(e)
            }
        },
        async getAttributesByType(e, typeId = null) {
            this.isOpening = true
            try {
                const idType = typeId ? typeId : e.target.value

                const response = await Api.get('attributes/findByType/' + idType)
                if (response.status === 200) {
                    if (this.name === 'Edit') {
                        if (idType != this.originalProduct.type_id) {
                            this.form.attributes = response.data
                        } else {
                            this.form.attributes = response.data.map((a) => {
                                if (a.type == 'select') {
                                    this.originalProduct.variations.forEach((v) => {
                                        if (v.attribute.id == a.id) {
                                            a.value = v.id
                                        }
                                    })
                                }

                                if (a.type == 'text') {
                                    this.originalProduct.attributes.forEach((v) => {
                                        if (v.id == a.id) {
                                            a.value = v.pivot.value
                                        }
                                    })
                                }

                                return a;
                            })
                        }
                    } else {
                        this.form.attributes = response.data
                    }
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.isOpening = false
            }
        },
		async update() {
			try {
				this.loading = true

				let formData = new FormData()

                formData.append('_method', 'put')
				formData.append('name', this.form.name)
				formData.append('description', this.form.description)
                formData.append('price', this.form.price)
                formData.append('discount_price', this.form.discount_price)

                if (this.form.image) {
					formData.append('image', this.form.image)
				}

                this.form.images.forEach((g) => {
                    formData.append('images[]', g)
                })

                this.form.attributes.forEach((a, i) => {
                    formData.append('attributes[' + i + '][id]', a.id)
                    formData.append('attributes[' + i + '][type]', a.type)
                    formData.append('attributes[' + i + '][value]', a.value || '')
                })

                for (var i = 0; i < this.form.categories.length; i++) {
					formData.append('categories[]', this.form.categories[i].id)
				}

				const response = await Api.post(`products/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/products')
					successToast(this.$t('updated_success'))
					eventHub.$emit('reload-products')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('updated_error'))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`products/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form.name = data.name
						this.form.description = data.description
                        this.form.price = parseFloat(data.price)
                        this.form.discount_price = parseFloat(data.discount_price)
                        this.form.types = data.types
                        this.form.categories = data.categories

                        this.imageProduct = data.image
                        this.imagesProduct = data.images ?? []

                        this.originalProduct = data
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()

				formData.append('name', this.form.name)
				formData.append('description', this.form.description)
                formData.append('price', this.form.price)
                formData.append('discount_price', this.form.discount_price)

                if (this.form.image) {
					formData.append('image', this.form.image)
				}

                this.form.images.forEach((g) => {
                    formData.append('images[]', g)
                })

                this.variations.forEach((a, i) => {
                    formData.append('variations[' + i + '][id]', a.id)
                    formData.append('variations[' + i + '][type]', a.type)

                    if (a.value && Array.isArray(a.value)) {
                        a.value.forEach((a1, i1) => {
                            formData.append('variations[' + i + '][value][' + i1 + '][id]', a1.id)
                            formData.append('variations[' + i + '][value][' + i1 + '][name]', a1.name)
                            formData.append('variations[' + i + '][value][' + i1 + '][variation_id]', a1.variation_id)
                        })
                    } else {
                        formData.append('variations[' + i + '][value]', a.value || '')
                    }
                })

                this.attributes.forEach((a, i) => {
                    formData.append('attributes[' + i + '][id]', a.id)
                    formData.append('attributes[' + i + '][value]', a.value || '')
                })

                for (var i = 0; i < this.form.categories.length; i++) {
					formData.append('categories[]', this.form.categories[i].id)
				}

                for (var i2 = 0; i2 < this.form.types.length; i2++) {
					formData.append('types[]', this.form.types[i2].id)
				}

				const response = await Api.post('products/store', formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/products')
					successToast(this.$t('created_success'))
					eventHub.$emit('reload-products')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('created_error') + '! <small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveForm() {
			this.name === 'New' ? await this.store() : await this.update()
		},
        getAttributesVariationsByTypes(types) {
            let ids = []
            types.forEach((v) => {
                ids.push(v.id)
            })

            Api.post('variations/findByTypes', { types: ids })
                .then(({ data }) => {
                    this.variations = data

                    if (this.name === 'Edit') {
                        this.setVariationsProduct()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

            Api.post('attributes/findByTypes', { types: ids })
                .then(({ data }) => {
                    this.attributes = data

                    if (this.name === 'Edit') {
                        this.setAttributesProduct()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        setVariationsProduct() {
            if (this.originalProduct) {
                this.variations = this.variations.map((vType) => {
                    if (vType.type == 'select') {
                        this.originalProduct.variations.forEach((vProduct) => {
                            if (vProduct.variation_id == vType.id) {
                                vType.value = vProduct.variation_vars
                            }
                        })
                    }

                    return vType
                })
            }
        },
        setAttributesProduct() {
            if (this.originalProduct) {
                this.attributes = this.attributes.map((a) => {
                    this.originalProduct.attributes.forEach((aProduct) => {
                        if (aProduct.pivot.attribute_id == a.id) {
                            a.value = aProduct.pivot.value
                        }
                    })

                    return a
                })
            }
        },
        deleteVariation(variation) {
            let va = this.variations.findIndex(v => v.id == variation.id)

            this.variations.splice(va, 1)
        },
        deleteAttribute(attribute) {
            let att = this.attributes.findIndex(a => a.id == attribute.id)

            this.attributes.splice(att, 1)
        }
	},
	mounted() {
        this.getAttributesVariationsByTypes([])
		this.findAllTypes()
        this.findAllCategories()
		this.findById()
	},
    watch: {
        'form.types'(v) {
            this.getAttributesVariationsByTypes(v)
        }
    }
}
</script>
