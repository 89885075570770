<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('products', 'create')" type="is-primary create" rounded @click="modalCreate($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field :label="$t('labels.order')" label-position="on-border">
					<b-select v-model="order" :placeholder="$tc('fields.name')" @input="filterByOrder">
						<option selected value="name">{{ $tc('fields.name') }}</option>
						<option value="created_at">{{ $tc('fields.date') }}</option>
					</b-select> 
				</b-field>
				<b-field>
					<b-input :placeholder="$tc('buttons.search')" type="search" icon-right="magnify" @input="findByName" v-model="name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :back="true" />
		<Results v-if="products.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="r in placeholder" :key="r" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="p in products" :key="p.id" class="list-item column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block" :style="{ 'border-left-color': p.color }" @click.self="modalEdit(p.id)">
					<div class="block__avatar image is-48x48" @click="modalEdit(p.id)">
						<b-image ratio="1by1" :src="p.image" :alt="p.name" :rounded="false"></b-image>
					</div>
					<div class="block__content" @click="modalEdit(p.id)">
						<h3 class="block__name" translate="no">{{ p.name }}</h3>
						<p class="block__email">{{ format(p.created_at) }} • {{ timeTo(p.created_at) }}</p>
					</div>
					<Trigger :id="p.id" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Trigger from '@/components/triggers/Product'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Product'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { errorToast, successToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import mixinApp from '@/mixins/app'

export default {
	name: 'Product',
	mixins: [
		responsive,
		mixinApp
	],
	components: {
		Layout,
		Header,
		Placeholder,
		Trigger,
		Error,
		Results,
		'svg-icon': Icon
	},
    data() {
		return {
			loading: true,
			placeholder: 12,
			order: 'name',
			name: '',
			errored: false,
			products: []
		}
	},
	methods: {
        async findAll() {
			this.products = []
			this.loading = true
			try {
				const response = await Api.get('products/findAll')
				if (response.status === 200) {
					this.products = response.data
				}
			} catch (e) {
				console.log(e)
				errorToast(this.$t('alerts.load_error'))
			} finally {
				this.loading = false
			}
        },
		async filterByOrder() {
			this.products = []
			this.loading = true
			try {
				const response = await Api.post('products/filterByOrder', { order: this.order })
				if (response.status === 200) {
					this.products = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			this.products = []
			this.loading = true
			try {
				const empty = /^\s*$/
				if (!empty.test(this.name)) {
					const response = await Api.post('products/findByName', { name: this.name })
					if (response.status === 200) {
						this.products = response.data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		modalCreate() {
			if (this.permissionEnabled('products', 'create')) {
				create(this, 'products', Modal, 'New')
			}
		},
		modalEdit(id) {
			if (this.permissionEnabled('products', 'edit')) {
				update(this, 'products', id, Modal, 'Edit')
			}
		}
	},
	mounted() {
		eventHub.$on('edit-modal-product', obj => {
			update(this, 'products', obj.id, Modal, 'Edit')
		})

		eventHub.$on('reload-products', () => {
			this.findAll()
		})

		eventHub.$on('delete-product', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('attention') + '!',
				message: '<span>' + this.$t('alert_delete') + '<span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('no'),
				confirmText: this.$t('yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`products/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('removed_success'))
							this.findAll()
						}
					} catch (e) {
						console.log(e)
						errorToast(this.$t('removed_error'))
					}
				}
			})
		})

        this.findAll()
    },
	destroyed() {
		eventHub.$off('edit-modal-product')
		eventHub.$off('reload-product')
		eventHub.$off('delete-product')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
	}
}
</script>
